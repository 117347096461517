<template>
  <div class="cooperation">
    <div class="aft_banner">
      <!-- <img src="../assets/images/coopBanner.png" alt="" /> -->
      <img :src="$host + banner.litpic" alt="" />
    </div>
    <div class="selectToggle">
      <div class="box flexCenter m160">
        <p class="">
          <router-link to="/cooperation/atrract">招商合作</router-link>
        </p>
        <p class="">
          <router-link to="/cooperation/business">OEM-ODM业务</router-link>
        </p>
        <p class="active">
          <router-link to="/cooperation/information">采购信息公示</router-link>
        </p>
      </div>
    </div>
    <div class="aft_title p80">
      <div class="box">
        <p>{{ list_care.englist_name }}</p>
        <div>{{ list_care.typename }}</div>
      </div>
    </div>
    <div class="information">
      <div class="box m160">
        <div class="item" v-for="(item, index) in new_list" :key="index">
          <router-link :to="'/cooperation/info?id=' + item.aid">
            {{ item.title }}
            <span>{{ (item.add_time * 1000) | dateFmt("YYYY.MM.DD") }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="aft_fengye">
      <div class="box flexCenter">
        <div class="btn prev" @click="PagePrev()">
          <a
            ><p>
              <svg
                t="1655284142230"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2370"
                data-spm-anchor-id="a313x.7781069.0.i6"
                width="14"
                height="14"
              >
                <path
                  d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                  p-id="2371"
                  fill="#ffffff"
                ></path>
              </svg></p
          ></a>
        </div>

        <div class="page_ flexCenter">
          <!-- <a href="" class="active"><span>01</span></a> -->

          <a
            v-for="(item, index) in last_page"
            :key="index"
            :class="{ active: PageIndex == index }"
            @click="changeHandler(index)"
          >
            <span v-if="index < 9">0{{ index + 1 }}</span>
            <span v-else>{{ index + 1 }}</span>
          </a>
        </div>
        <div class="btn next" @click="PageNext()">
          <a
            ><p>
              <svg
                t="1655284142230"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2370"
                data-spm-anchor-id="a313x.7781069.0.i6"
                width="14"
                height="14"
              >
                <path
                  d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                  p-id="2371"
                  fill="#ffffff"
                ></path>
              </svg></p
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "information",
  components: {},
  data() {
    return {
      banner: {},
      list_care: {},
      new_list: [],
      pages: 1,
      last_page: 1,
      PageIndex: 0,
    };
  },
  created() {
    this.$http.get("api.php/Webinfo/cgxx").then(
      function (res) {
        const information = JSON.parse(res.data);
        this.banner = information.banner;
        this.list_care = information.newsList.cate;
        this.new_list = information.newsList.list;
        this.last_page = information.last_page;

        console.log(information);
      },
      function (res) {
        console.log(res.status);
      }
    );
  },
  methods: {
    PagePrev() {
      if (this.pages > 1) {
        this.PageIndex--;
        this.pages--;
        this.$http
          .get(
            "api.php/Webinfo/cgxx",
            {
              params: {
                page: this.pages,
              },
            },
            {
              emulateJSON: true,
            }
          )
          .then(
            function (res) {
              var news_data = JSON.parse(res.data);
              this.new_list = news_data.newsList.list;
            },
            function (res) {
              console.log(res.status);
            }
          );
      }
      window.scrollTo({
        top: 0,
      });
    },
    PageNext() {
      if (this.pages < this.last_page) {
        this.PageIndex++;
        this.pages++;
        this.$http
          .get(
            "api.php/Webinfo/cgxx",
            {
              params: {
                page: this.pages,
              },
            },
            {
              emulateJSON: true,
            }
          )
          .then(
            function (res) {
              var news_data = JSON.parse(res.data);
              this.new_list = news_data.newsList.list;
            },
            function (res) {
              console.log(res.status);
            }
          );
        window.scrollTo({
          top: 0,
        });
      }
    },
    changeHandler(i) {
      this.PageIndex = i;

      this.pages = i + 1;

      this.$http
        .get(
          "api.php/Webinfo/cgxx",
          {
            params: {
              page: this.pages,
            },
          },
          {
            emulateJSON: true,
          }
        )
        .then(
          function (res) {
            var news_data = JSON.parse(res.data);
            this.new_list = news_data.newsList.list;
          },
          function (res) {
            console.log(res.status);
          }
        );
      window.scrollTo({
        top: 0,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.information {
  margin-top: 0.54rem;
  .box {
    .item {
      padding: 0.3rem 0;
      border-bottom: 1px solid #ddd;
      a {
        font-size: 0.2rem;
        font-family: @albb_m;
        color: #333;
        line-height: 0.3rem;
        display: block;
        padding-left: 0.26rem;
        padding-right: 20%;
        position: relative;
        span {
          font-family: @gilroy_r;
          color: #999;
          position: absolute;
          top: 0;
          right: 0;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0.12rem;
          left: 0;
          width: 5px;
          height: 5px;
          background: @color;
          border-radius: 50%;
        }
      }
    }
  }
}
.aft_fengye {
  padding: 20px 0;
}
@media screen and(max-width:768px) {
  .information .box .item a[data-v-5c5c16e2] {
    padding-right: 22%;
  }
}
</style>
